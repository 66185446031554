/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './index.scss';

export default function SyncAnnoucement(): React.ReactElement {
  return (
    <div className="navigationBanner">
      <span className="text-sm">
        📢 Important Announcement: The Sync feature will officially transition
        from beta to production on March 20th at 00:00 UTC. This feature will be
        exclusively available to Developer and Enterprise users. For more
        details and pricing information, please visit our
        {' '}
        <a
          href="https://platform.chainbase.com/pricing"
          target="_blank"
          rel="noreferrer"
        >
          pricing page
        </a>
        .
      </span>
    </div>
  );
}
