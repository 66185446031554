import React from 'react';
import { Button, Typography } from '@douyinfe/semi-ui';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/components';
import { TableSchema } from '@/model/Table';
import _ from 'lodash';
import { hasIcon } from '@/components/Icon';

type TableDescriptionPopoverType = {
  data: TableSchema,
  onPreview: (tableName: string) => void
}

export default function TableDescriptionPopover(
  {
    data,
    onPreview
  }: TableDescriptionPopoverType
): React.ReactElement {
  const { t } = useTranslation();
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >

      <div className="info">
        <Typography.Text className="name" title="h3">
          {data.name}
        </Typography.Text>

        <p className="description">
          {data?.description}
        </p>

        {
          data?.schema ? (
            <div className="blockchains">
              {
              _.map(data?.schema, (item) => (hasIcon(`blockchain/${(item === 'bsc_testnet' ? 'BSC' : item)?.toLowerCase()}-solid`) ? (
                <Icon
                  key={item}
                  className="icon rounded-[50%]"
                  icon={`blockchain/${(item === 'bsc_testnet' ? 'BSC' : item)?.toLowerCase()}-solid`}
                />
              ) : null))
            }

            </div>
          ) : null
        }
      </div>
      <Button
        icon={<Icon className="icon" icon="dataCloud/preview" />}
        className="btn-preview"
        theme="borderless"
        onClick={(e) => {
          e.stopPropagation();
          onPreview(data.table_name);
        }}
      >
        {t('dataCloud.previewData')}
      </Button>
    </div>
  );
}
