import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Collapsible } from '@douyinfe/semi-ui';
import UserMenu from '@/components/Navigation/UserMenu';
import Icon from '../Icon';
import StatusButton from './StatusButton';
import FooterLink from './FooterLink';

/**
 * interfaces & types
 */
export declare type NavigationItemProps = {
  key: string;
  icon: string;
  link?: string;
  target?: string;
  replace?: boolean;
  titleSubfixIcon?: string;
  hoverSubfix?: string;
  content?: NavigationItemProps[];
}

type NavigationGroupProps = {
  title: string,
  titleIcon?: React.ReactElement,
  items: NavigationItemProps[]
  cls?: string
}

/**
 * constants
 */
const defaultNavigationGroupProps: NavigationGroupProps = {
  title: '',
  titleIcon: undefined,
  items: [],
  cls: ''
};

/**
 * function component
 */
function NavIcon({ className, icon }: Omit<{
  className?: string,
  icon: string | null
}, ''>): React.ReactElement | null {
  if (!icon) {
    return null;
  }
  return (
    <Icon className={classNames('icon', className)} icon={icon} />
  );
}

export function NavigationItem(props: {
  item: NavigationItemProps;
  isCollapseTitle?: boolean;
  onClick?: () => void;
  isSubdirectory?: boolean;
}): React.ReactElement {
  const { t } = useTranslation();
  const {
    item,
    isCollapseTitle,
    isSubdirectory,
    onClick
  } = props;

  const content = (
    <>
      <NavIcon icon={item.icon} className={_.last(item.icon.split('/'))} />
      <span>{t(item.key)}</span>
      <span>
        {item.titleSubfixIcon && (
        <Icon
          className="navigation-item-title-subfix"
          icon={item.titleSubfixIcon}
        />
        )}
      </span>
      {item.hoverSubfix && (
      <Icon
        className="icon hover-subfix"
        icon={item.hoverSubfix}
        style={{ position: 'absolute', right: 0 }}
      />
      )}
    </>
  );

  if (isCollapseTitle) {
    if (!onClick) {
      throw new Error('Unexpected undefined of \'onClick\' when item is collapse title');
    }

    return (
      <button
        type="button"
        className={classNames(['navigation-item'])}
        onClick={() => {
          onClick();
          const arrowIcon = document.querySelector('.collapse-arrow');
          arrowIcon?.classList.toggle('expanded');
        }}
      >
        {content}
        <Icon icon="common/arrow-left" className="collapse-arrow" />
      </button>
    );
  }

  if (!item.link) {
    return (
      <div className={
        classNames(
          ['navigation-item', 'medium', 'disabled', 'relative']
        )
        }
      >
        {content}
        <Icon className="soon" icon="navigation/soon" />
      </div>
    );
  }

  if (item.link.startsWith('http')) {
    return (
      <a
        className={classNames('navigation-item', 'regular', 'relative')}
        href={item.link}
        target={item.target}
        rel="noreferrer"
      >
        {content}
      </a>
    );
  }

  return (
    <NavLink
      key={item.key}
      className={({
        isActive
      }) => classNames(
        ['navigation-item', {
          'active medium': isActive,
          regular: !isActive
        }, {
          'navigation-item-subdirectory': isSubdirectory
        }]
      )}
      to={item.link}
      target={item.target}
      replace={item.replace}
    >
      {content}
    </NavLink>
  );
}

function NavigationCollapse({ item }:{item: NavigationItemProps}): React.ReactElement {
  const {
    key, icon, content, titleSubfixIcon, hoverSubfix
  } = item;

  if (!content) {
    throw new Error('Unexpected \'undefined\' of content');
  }

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <NavigationItem
        item={{
          key,
          icon,
          titleSubfixIcon,
          hoverSubfix
        }}
        isCollapseTitle
        onClick={() => setIsOpen((prev) => !prev)}
      />
      <Collapsible isOpen={isOpen}>
        {content.map((navItem) => (
          <NavigationItem key={navItem.key} item={navItem} isSubdirectory />
        ))}
      </Collapsible>
    </div>
  );
}

function NavigationGroup({
  title,
  titleIcon,
  items,
  cls
} = defaultNavigationGroupProps): React.ReactElement {
  return (
    <div className={`navigation-group ${cls}`}>
      <h3 className={classNames('title', 'bolder')}>
        {title}
        {titleIcon}
      </h3>
      {
        _.map(items, (item) => (item.content
          ? <NavigationCollapse key={item.key} item={item} />
          : <NavigationItem key={item.key} item={item} />))
      }
    </div>
  );
}

export default function Navigation(): React.ReactElement {
  const { t } = useTranslation();
  const APINavigationItems: NavigationItemProps[] = [
    {
      key: 'navigation.dashboard',
      icon: 'navigation/dashboard',
      link: '/'
    },
    {
      key: 'navigation.logExplorer',
      icon: 'navigation/logExplorer',
      link: '/logExplorer'
    }
  ];

  const DataCloudItems: NavigationItemProps[] = [
    {
      key: 'navigation.query',
      icon: 'navigation/dataCloud',
      link: '/dataCloud/v2'
    },
    {
      key: 'navigation.archived',
      icon: 'navigation/archivedQuery',
      link: '/archivedQueries'
    },
    {
      key: 'navigation.apis',
      icon: 'navigation/apis',
      link: '/apis'
    }
  ];

  const SuiteNavigationItems: NavigationItemProps[] = [
    {
      key: 'navigation.sync',
      icon: 'navigation/pipelines',
      titleSubfixIcon: 'navigation/sync/new',
      content: [{
        key: 'navigation.sync-jobs',
        icon: 'navigation/sync/jobs',
        link: '/sync/jobs'
      }, {
        key: 'navigation.sync-integrations',
        icon: 'navigation/sync/integrations',
        link: '/sync/integrations'
      }, {
        key: 'navigation.sync-webhook',
        icon: 'navigation/sync/webhook',
        link: '/sync/webhook'
      }, {
        key: 'navigation.sync-kafka',
        icon: 'navigation/sync/kafka',
        link: '/sync/kafka'
      }]
    },
    // {
    //   key: 'navigation.pipelines',
    //   icon: 'navigation/pipelines',
    //   link: '/pipelines'
    // },
    {
      key: 'navigation.datasets',
      icon: 'navigation/datasets',
      link: 'https://platform.chainbase.com/datasets',
      hoverSubfix: 'common/open',
      target: '__blank'
    }
    // {
    //   key: 'navigation.usage',
    //   icon: 'navigation/usage',
    //   link: '/usage'
    // }
  ];

  const footerLinkItems: NavigationItemProps[] = [
    {
      key: 'navigation.documents',
      icon: 'navigation/document',
      link: 'https://docs.chainbase.com/platform/overview'
    },
    {
      key: 'navigation.help',
      icon: 'navigation/help',
      link: 'https://platform.chainbase.com/help'
    }
  ];

  return (
    <div className="navigation">
      <a className="logo-link" href="/">
        <Icon className="logo" icon="header/Logo" />
      </a>
      <div className="navigation-list">
        <NavigationGroup
          title={t('navigation.apiIntelligence')}
          items={APINavigationItems}
          cls="navigation-group-api"
        />

        <NavigationGroup
          title={t('navigation.dataCloudTitle')}
          items={DataCloudItems}
          cls="navigation-group-datacloud"
        />

        <NavigationGroup
          title={t('navigation.dataInfrastructure')}
          items={SuiteNavigationItems}
          cls="navigation-group-dataInfura"
        />
      </div>

      <div className="bottom-group">

        <div className="p-4 border border-[#444D56] border-solid border-x-0">
          <StatusButton />
          <div className="flex justify-center items-center mt-4">
            <FooterLink data={footerLinkItems[0]} />
            <div className="border-[0.5px] border-solid border-[#444D56]" />
            <FooterLink data={footerLinkItems[1]} />
          </div>
        </div>
        <UserMenu />
      </div>
    </div>
  );
}
