import React, { Suspense, lazy } from 'react';
import {
  PathRouteProps, Route, RouteProps, Routes, unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Navigation, SyncAnnoucement } from '@/components';
import RequireAuth from '@/config/auth/RequireAuth';
import SettingNavigation from '@/components/Navigation/SettingNavigation';
import Billing from '@/views/settings/billing';
import PayLink from '@/views/settings/payment/PayLink';
import Error from '@/views/error';
import Layout from '@/views/layout';
import LoginLayout from '@/views/layout/login';

import Login from '@/views/login/login';
import Register from '@/views/login/register';
import SendResetEmail from '@/views/login/reset';
import ResetPassword from '@/views/login/resetPassword';
import ModifySuccessful from '@/views/login/modifySuccessful';
import EmailActivation from '@/views/login/register/emailActivation';

import Dashboard from '@/views/dashboard/index';
import ActivationStatus from '@/views/login/register/ActivationStatus';
import Redirect from '@/views/redirect';
import LogExplorer from '@/views/dashboard/log';

import DefaultLayout from '@/views/layout/DefaultLayout';
import DataCloudTerminal from '@/views/dataCloud/index';
import ArchivedQueries from '@/views/dashboard/archivedQueries';
import NavHeader from '@/components/Layout/NavHeader';
import Composer from '@/views/dashboard/composer';
import APIList from '@/views/api/list';
import { BlockLoading } from './components/Loading/BlockLoading';
import ProjectLayout from './views/project/ProjectLayout';
import useTrack from './utils/useTrack';
import SyncIntegrations from './views/sync/integrations';
import SyncJobs from './views/sync/jobs';
import JobCreation from './views/sync/job-creation';
import JobDetail from './views/sync/job-detail';
import DataCloudTerminalV2 from './views/dataCloudStudio/index';
import IntegrationDetail from './views/sync/integration-detail';
import IntegrationCreation from './views/sync/integration-creation';

const EndpointsView = lazy(() => import('@/views/project/Endpoints'));
const SecurityView = lazy(() => import('@/views/project/Security'));
const SyncIndexView = lazy(() => import('@/views/sync/index'));
const OnboardingQuestionaireView = lazy(() => import('@/views/onboarding/NewUserQuestion'));
const SyncKafkaIndexView = lazy(() => import('@/views/sync/kafka'));
const SyncWebhookIndexView = lazy(() => import('@/views/sync/webhook'));
const CustomAPI = lazy(() => import('@/views/api/custom'));

const RouterConfig: Array<PathRouteProps | RouteProps> = [
  {
    index: true,
    element: <Dashboard />
  },
  {
    path: '/logExplorer',
    element: <LogExplorer />
  },
  {
    path: '/composer',
    element: <Composer />
  },
  {
    path: '/archivedQueries',
    element: <ArchivedQueries />
  },
  {
    path: '/apis',
    element: <APIList />
  }
];

const SyncRouterConfig: Array<PathRouteProps | RouteProps> = [
  {
    path: '/sync',
    element: (
      <Suspense fallback={<BlockLoading />}>
        <SyncIndexView />
      </Suspense>
    )
  },
  {
    path: '/sync/kafka',
    element: (
      <Suspense fallback={<BlockLoading />}>
        <SyncKafkaIndexView />
      </Suspense>
    )
  },
  {
    path: '/sync/webhook',
    element: (
      <Suspense fallback={<BlockLoading />}>
        <SyncWebhookIndexView />
      </Suspense>
    )
  },
  {
    path: '/sync/integrations/create',
    element: (<IntegrationCreation />)
  },
  {
    path: '/sync/integrations/:integrationId',
    element: (<IntegrationDetail />)
  },
  {
    path: '/sync/integrations',
    element: (<SyncIntegrations />)
  },
  {
    path: '/sync/jobs/create',
    element: (<JobCreation />)
  },
  {
    path: '/sync/jobs/:jobId',
    element: (<JobDetail />)
  },
  {
    path: '/sync/jobs',
    element: (<SyncJobs />)
  }
];

const DefaultRoutesConfig: Array<PathRouteProps | RouteProps> = [
  {
    path: '/dataCloud/v1',
    element: <DataCloudTerminal />
  },
  {
    path: '/dataCloud/v2',
    element: <DataCloudTerminalV2 />
  },
  {
    path: '/dataCloud/api/custom/',
    element: (
      <Suspense fallback={<BlockLoading />}><CustomAPI /></Suspense>
    )
  },
  {
    path: '/dataCloud/api/custom/:id',
    element: (
      <Suspense fallback={<BlockLoading />}><CustomAPI /></Suspense>
    )
  },
  {
    path: '/dataCloud',
    element: <DataCloudTerminal />
  },
  {
    path: '/onboarding/user-customize',
    element: (
      <Suspense fallback={<BlockLoading />}>
        <OnboardingQuestionaireView />
      </Suspense>
    )
  }
];

const SettingsRouterConfig: Array<PathRouteProps | RouteProps> = [
  {
    path: '/settings/billing',
    element: <Billing />
  }
];

const ProjectRouterConfig: Array<PathRouteProps | RouteProps> = [
  {
    path: '/project/:projectId/endpoints',
    element: (
      <Suspense fallback={<BlockLoading />}>
        <EndpointsView />
      </Suspense>
    )
  },
  {
    path: '/project/:projectId/security',
    element: (
      <Suspense fallback={<BlockLoading />}>
        <SecurityView />
      </Suspense>
    )
  }
];

export const history = createBrowserHistory();

const Track: React.FC = () => {
  useTrack();
  return null;
};

function App(): React.ReactElement {
  const { t } = useTranslation('translation');
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route element={<LoginLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset" element={<SendResetEmail />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/modifySuccessful" element={<ModifySuccessful />} />
          <Route path="/emailActivation" element={<EmailActivation />} />
          <Route path="/activationStatus/:status" element={<ActivationStatus />} />
        </Route>
        <Route element={<DefaultLayout />}>
          {_.map(DefaultRoutesConfig, (item) => (
            <Route key={item.path} path={item.path} element={<RequireAuth>{item.element}</RequireAuth>} />
          ))}
        </Route>

        <Route element={<Layout navigation={<Navigation />} />}>
          {_.map(RouterConfig, (item) => (
            <Route
              key={item.path || `Index:${item.index}`}
              index={item.index}
              path={item.path}
              element={<RequireAuth key={item.path}>{item.element}</RequireAuth>}
              caseSensitive={item.caseSensitive}
            />
          ))}

          <Route path="*" element={<Error />} />
        </Route>

        <Route element={<Layout navigation={<Navigation />} header={<SyncAnnoucement />} />}>
          {_.map(SyncRouterConfig, (item) => (
            <Route
              key={item.path || `Index:${item.index}`}
              index={item.index}
              path={item.path}
              element={<RequireAuth key={item.path}>{item.element}</RequireAuth>}
              caseSensitive={item.caseSensitive}
            />
          ))}

          <Route path="*" element={<Error />} />
        </Route>

        <Route
          element={(
            <Layout
              header={<NavHeader routers={[t('common.settings')]} />}
              className="settings"
              navigation={<SettingNavigation />}
            />
          )}
          path="/settings"
        >
          {_.map(SettingsRouterConfig, (item) => (
            <Route
              key={item.path || `Index:${item.index}`}
              index={item.index}
              path={item.path}
              element={<RequireAuth key={item.path}>{item.element}</RequireAuth>}
              caseSensitive={item.caseSensitive}
            />
          ))}

          <Route path="*" element={<Error />} />
        </Route>

        <Route element={<ProjectLayout />} path="/project/:projectId">
          {_.map(ProjectRouterConfig, (item) => (
            <Route
              key={item.path || `Index:${item.index}`}
              index={item.index}
              path={item.path}
              element={<RequireAuth key={item.path}>{item.element}</RequireAuth>}
              caseSensitive={item.caseSensitive}
            />
          ))}

          <Route path="*" element={<Error />} />
        </Route>

        <Route path="/payment" element={<PayLink />} />
        <Route path="/redirect" element={<Redirect />} />

        <Route path="/*" element={<Error />} />
      </Routes>
      <Track />
    </HistoryRouter>
  );
}

export default App;
