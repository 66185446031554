export interface WalletItem {
  icon: string;
  name: string;
  popular?: boolean;
}

export const WalletList: Array<WalletItem> = [
  {
    icon: 'wallet/okx',
    name: 'OKX Wallet',
    popular: true
  },
  {
    icon: 'wallet/metamask',
    name: 'Metamask'
  },
  {
    icon: 'wallet/fortmatic',
    name: 'Fortmatic'
  },
  {
    icon: 'wallet/wallet-connect',
    name: 'WalletConnect'
  }
];
